import React, { Component } from "react";
import "./../../assets/styles/OthersBooks.css";

// TODO: Transformar em compoennte funcional

class OthersBooks extends Component {
  state = {
    SLIDES: [],
    currentSlide: 0,
  };

  componentDidMount() {
    return this.loadBooks();
  }

  loadBooks() {
    let curentBooks = this.props.payload.listData;

    this.setState({
      SLIDES: curentBooks,
      currentSlide: curentBooks.length < 2 ? 0 : curentBooks.length - 2
    });
  }

  handleClick = e => {
    const slides = this.state.SLIDES;
    const totalItens = slides.length;
    const id = e.target.id;
    // aqui é a seleção do slide no carrossel 
    for (let j = 0; j < totalItens; j++) {
      const element = slides[j];
      if (id === element.bookId) {
        // marca o slide para exibir
        setTimeout(() => {
          // aciona o scroll pro topo
          window.scrollTo(0, 0)
          this.props.onSlideChange(id);
        }, 200);
        // finaliza o "for"
        j = totalItens;
      }
    }
  }

  render() {
    return (
      <div id="others_books_container_overflow" className="others_books_container_overflow">
        <>
          {this.state.SLIDES.map(item => {
            const encodedUrl = encodeURI(item.bookCover);

            return (
            <div key={item.bookId}>
              <div
                id={item.bookId}
                onClick={this.handleClick}
                className="others_books_slide"
                style={{ backgroundImage: `url(${encodedUrl})` }}
              />
              <p id="availability">{`Baixe até ${item.bookAvailability}`}</p>
            </div>
          )})}
        </>
      </div>
    );
  }
}

export default OthersBooks;
